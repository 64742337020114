


































import DealerDetail from '@/components/DealerDetail.vue';
import DealerDualMenu from '@/components/DealerDualMenu.vue';
import SortableTable from '@/components/SortableTable.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import { ProductListResponse } from '@/types/api/Product';
import debounce from 'debounce';
import Vue from 'vue';
import { cleanupFilterRequest, createFilterRequest } from '@/types/api/FilterRequest';
export default Vue.extend({
  'name': 'DealerStockTrackingList',
  'components': {
    DealerDetail,
    DealerDualMenu,
    SortableTable
  },
  data () {
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': 'product.model',
          'display': 'Model',
          'width': 250,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.serialNo',
          'display': 'Serial No.',
          'width': 120,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.totalSqFt',
          'display': 'Total Sq. Ft.',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'validYears',
          'display': 'Warranty Years',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.invoiceNo',
          'display': 'Invoice No.',
          'width': 200,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'product.deliveryDate',
          'display': 'Delivery Date',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': [],
          'valueFn': (value) => { return value.toFixed(1); }
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'buttons': []
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as unknown,
      'debounceGetList': null as unknown
    };
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      this.axios
        .get('product/list', {
          'params': getRequest
        })
        .then(({ data }) => {
          const response = data as ProductListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    }
  }
});
