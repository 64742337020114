











































































































































































import { DropdownOption } from '@/dropdown-options';
import ModalAddNewDealer from '@/components/modals/AddNewDealer.vue';
import {
  assignDealerDetails,
  createNewDealerDetailResponse,
  DealerResponse,
  initForm,
  updateForm
} from '@/types/api/DealerList';
import { DealerTypeDataResponse } from '@/types/api/DropdownData';
import Vue from 'vue';
export default Vue.extend({
  'name': 'DealerDetail',
  'components': {
    ModalAddNewDealer
  },
  'computed': {
    isSuperAdminOrAdmin (): boolean {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    viewDealerId (): number | null {
      return this.$store.getters.viewDealerId;
    },
    dealerTypeDisplay (): string {
      if (this.dealerTypes.length === 0) return '';

      const dealerType = this.dealerTypes.find(
        (d) => d.value === this.dealerDetails.dealerTypeId
      );
      if (dealerType) return dealerType.text;

      return '';
    }
  },
  'watch': {
    viewDealerId (dealerId: number | null) {
      this.getDealerDetails(dealerId);
    }
  },
  data () {
    const form = initForm();
    const dealerDetails = createNewDealerDetailResponse();
    return {
      form,
      dealerDetails,
      'dealerTypes': [] as Array<DropdownOption>
    };
  },
  mounted () {
    this.getDealerTypes();
    this.getDealerDetails(this.viewDealerId);
  },
  'methods': {
    getDealerTypes () {
      // @ts-expect-error: Ignore error explicitly.
      this.axios.get('data/dealer-types').then(({ data }) => {
        const response = data as DealerTypeDataResponse;

        const list = response.result.dealerTypes;
        this.dealerTypes = list.map((dealerType) => ({
          'text': dealerType.name,
          'value': dealerType.id
        }));
      });
    },
    getDealerDetails (dealerId: number | null) {
      // @ts-expect-error: Ignore error explicitly.
      this.axios.get(`dealer/${dealerId}`).then(({ data }) => {
        const response = data as DealerResponse;

        assignDealerDetails(this.dealerDetails, response.result);
      });
    },
    editInfo () {
      const dealerId = this.viewDealerId;
      // @ts-expect-error: Ignore error explicitly.
      this.axios.get(`dealer/${dealerId}`).then(({ data }) => {
        const response = data as DealerResponse;

        updateForm(this.form, response.result);

        (this.$refs.modalAddNewDealer as any).update();
      });
    },
    submitForm () {
      this.axios
        .put('dealer', this.form)
        .then(() => {
          (this.$refs.modalAddNewDealer as any).hide();

          this.getDealerDetails(this.viewDealerId);
        })
        .catch((err:any) => {
          if (
            err.response &&
              err.response.status === 422 &&
              err.response.data
          ) {
            const result = err.response.data.result;
            if (result) {
              (this.$refs.modalAddNewDealer as any).setErrors(result);
            }
          }
        });
    }
  }
});
